import {getStrapiMedia} from "@utils/strapi";
import delve from "dlv";

const History = ({Image, Title, Description}: any) => {
  return (
    <div className="text-center mb-10 md:flex items-center lg:pl-6">
      <div className="w-full lg:w-2/3 xl:w-2/3 bg-cover aspect-video" style={{ backgroundImage: 'url(' + getStrapiMedia(delve(Image, 'data.attributes.url')) || undefined + ')'}}></div>
      <div className="w-11/12 lg:w-1/3 xl:w-1/3 -mt-10 -mb-4 md:my-0 md:-ml-6 bg-brand mx-auto pt-5 px-8 pb-5 md:py-8  text-center text-white">
        <h3 className="text-white mb-3">{Title}</h3>
        <span dangerouslySetInnerHTML={{__html: Description}}></span>
      </div>
    </div>
  )
}

History.defaultProps = {};

export default History;
