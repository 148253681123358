import Link from 'next/link'
import s from "./Header.module.scss";
import Image from "next/image";
import {FC} from "react";

interface Link {
  href: string
  label: string
}

interface HeaderProps {
  links?: Link[]
}

const Header: FC<HeaderProps> = ({links}) => (

  <div className={s.headerbar}>
    <Link legacyBehavior href="/">
      <a className={s.logo} aria-label="Logo">
        <Image
          className={s.img}
          src="/bora-hansgrohe.svg"
          alt="BORA hansgrohe German Professional Cycling"
          width={200}
          height={150}
          priority={false}
          quality="85"
        />
      </a>
    </Link>

    <Image
      className={s.img}
      src="/brand-of-brothers.svg"
      alt="Band of Brothers"
      width={100}
      height={65}
      priority={false}
      quality="85"
    />
  </div>

)

export default Header
