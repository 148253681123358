import {Button} from "@components/ui";
import 'keen-slider/keen-slider.min.css'
import Image from 'next/image'
import Expand from "@components/icons/Expand";
import {useState} from "react";
import {useKeenSlider} from "keen-slider/react";
import Lightbox from "yet-another-react-lightbox";
import {fetchJson, redirectTo404Page} from "../../pages";
import {useTranslation} from "next-i18next";
import CustomLightbox from "@components/CmsBlocks/CustomLightbox";


export interface Photo {
  id: string;
  secret: string;
  server: string;
  farm: number;
  title: string;
  isprimary: string;
  ispublic: number;
  isfriend: number;
  isfamily: number;
  url_sq: string;
  height_sq: number;
  width_sq: number;
  url_t: string;
  height_t: number;
  width_t: number;
  url_s: string;
  height_s: number;
  width_s: number;
  url_q: string;
  description: {_content: string};
  height_q: number;
  width_q: number;
  url_m: string;
  height_m: number;
  width_m: number;
  url_n: string;
  height_n: number;
  width_n: number;
  url_z: string;
  height_z: number;
  width_z: number;
  url_c: string;
  height_c: number;
  width_c: number;
  url_l: string;
  height_l: number;
  width_l: number;
  url_o: string;
  height_o: number;
  width_o: number;
}

export interface Photoset {
  id: string;
  primary: string;
  owner: string;
  ownername: string;
  photo: Photo[];
}

export interface FlickrAlbumPhotos {
  photoset: Photoset;
}


export function getGalleryPhotos(id: string) {
  return new Promise(async (resolve) => {
    const method = "flickr.photosets.getPhotos"; // https://www.flickr.com/services/api/flickr.photosets.getPhotos.html
    const apiKey = "3849884059e15ddd371a2300cb308d10";
    const userId = "125658326@N04";
    const extraData = "url_sq,url_t,url_s,url_q,url_m,url_n,url_z,url_c,url_l,url_o,description";
    const perPage = 50;
    const page = 1;
    const url = "https://api.flickr.com/services/rest/?method=" + method + "&api_key=" + apiKey + "&user_id=" + userId + "&photoset_id=" + id + "&format=json&extras=" + extraData + "&per_page=" + perPage + "&page=" + page + "&nojsoncallback=1";
    const photos: FlickrAlbumPhotos = await fetchJson(url);
    if (!photos) {
      resolve(null);
      return;
    }

    const slides = photos.photoset.photo.map((image: Photo) => {
      const {
        url_c, // medium (800px)
        url_n, // small (320px)
        url_z, // medium (640px)
        url_l, // large (1024px)
        url_o, // original
        url_m, // small (240px)
        url_q, // thumbnail (150px)
        url_s, // small (75px)
        url_t, // thumbnail (100px)
        height_c,
        width_c,
        height_n,
        width_n,
        height_z,
        width_z,
        height_l,
        width_l,
        height_o,
        width_o,
        height_m,
        width_m,
        height_q,
        width_q,
        height_s,
        width_s,
        description,
        height_t,
        width_t
      } = image;

      const obj: any = {
        src: url_o,
        type: 'bora-custom-slide',
        key: description._content,
        description: description._content,
        url: url_o,
        width: width_o,
        height: height_o,
        srcSet: []
      }
      let srcSetString = url_o + " " + width_o + "w, ";
      let sizes = width_o + "px, ";

      if (url_l) {
        srcSetString += url_l + " " + width_l + "w, ";
        sizes += width_l + "px, ";
        obj.srcSet.push({
          src: url_l,
          width: width_l,
          height: height_l
        })
      }
      if (url_m) {
        srcSetString += url_m + " " + width_m + "w, ";
        sizes += width_m + "px, ";
        obj.srcSet.push({
          src: url_m,
          width: width_m,
          height: height_m
        })
      }
      if (url_s) {
        srcSetString += url_s + " " + width_s + "w, ";
        sizes += width_s + "px, ";
        obj.srcSet.push({
          src: url_s,
          width: width_s,
          height: height_s
        })
      }
      if (url_t) {
        srcSetString += url_t + " " + width_t + "w";
        sizes += width_t + "px";
        obj.srcSet.push({
          src: url_t,
          width: width_t,
          height: height_t
        })
      }
      obj.srcSetString = srcSetString;
      obj.sizesString = sizes;
      return obj;
    })

    resolve(slides);
  });
}


export interface Title {
  _content: string;
}

export interface Description {
  _content: string;
}

export interface PrimaryPhotoExtras {
  url_sq: string;
  height_sq: number;
  width_sq: number;
  url_t: string;
  height_t: number;
  width_t: number;
  url_s: string;
  height_s: number;
  width_s: number;
  url_q: string;
  height_q: number;
  width_q: number;
  url_m: string;
  height_m: number;
  width_m: number;
  url_n: string;
  height_n: number;
  width_n: number;
  url_z: string;
  height_z: number;
  width_z: number;
  url_c: string;
  height_c: number;
  width_c: number;
  url_l: string;
  height_l: number;
  width_l: number;
  url_o: string;
  height_o: number;
  width_o: number;
}

export interface Photoset {
  id: string;
  owner: string;
  username: string;
  primary: string;
  secret: string;
  server: string;
  farm: number;
  count_views: string;
  count_comments: string;
  count_photos: number;
  count_videos: number;
  title: Title;
  description: Description;
  can_comment: number;
  date_create: string;
  date_update: string;
  photos: number;
  visibility_can_see_set: number;
  needs_interstitial: number;
  primary_photo_extras: PrimaryPhotoExtras;
}

export interface FlickrAlbum {
  photoset: Photoset;
  stat: string;
}

const Gallery = (data: any) => {

  const [currentSlide, setCurrentSlide] = useState(0)
  const [albums, setAlbums] = useState<FlickrAlbum[]>(data.albums)
  const [slides, setSlides] = useState<any>(false);
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    renderMode: 'performance',
    slides: {perView: 1, spacing: 15},
  })

  const loadGallery = async (id: string) => {
    if (!id) {
      return;
    }
    const slides: any = await getGalleryPhotos(id);
    if (!slides) {
      return redirectTo404Page();
    }
    setSlides(slides);
  };
  const { t } = useTranslation("common");
    return (
    <div className="relative pb-4 md:my-4">
      <div className="absolute -z-0 top-0 bg-accent-1 h-3/4 w-full"></div>
      <div className="relative z-10">
        <div className="my-4">
          <h4 className="py-4 px-10 text-light uppercase">{t("galleries")}</h4>
          {
            albums && albums.length > 0 &&
              <div className="px-10">
              <div ref={sliderRef} className="keen-slider">
                {albums?.map((album: FlickrAlbum, i: any) => {
                  return (
                    <div key={i} className="keen-slider__slide">
                      <div className="relative line-height-1" key={i}>
                        <Image
                          className="object-cover line-height-1"
                          src={album.photoset?.primary_photo_extras?.url_o}
                          alt={album.photoset?.description?._content}
                          width={1600}
                          height={900}
                          priority={i === 0}
                          quality="85"
                        />
                        <div className="absolute bottom-0 bg-white flex items-center max-w-full">
                          <h4 className="px-2 md:px-4 truncate">
                            {album.photoset?.title?._content}
                          </h4>
                          {/* todo lightbox open gallery */}
                          <Button onClick={(e) => loadGallery(album.photoset.id)} className="text-white"
                                  style={{padding: '10px', border: 'none'}}><Expand></Expand></Button>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <Lightbox
                  open={slides}
                  close={() => setSlides(false)}
                  slides={slides}
                  render={{
                    slide: (slide: any) =>
                      <CustomLightbox slide={slide} />
                  }}
                />
              </div>
            </div>
          }
          {loaded && instanceRef.current && (
            <>
              <Arrow
                left
                onClick={(e: { stopPropagation: () => any; }) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
                disabled={currentSlide === 0}
              />

              <Arrow
                onClick={(e: { stopPropagation: () => any; }) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1 /* todo: here it should access the perview breakpoint value instead of overall length or it will never reach last slide to be disabled */
                }
              />
            </>
          )}

        </div>

      </div>
    </div>
  )
}

function Arrow(props: any) {
  const disabled = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/>
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
      )}
    </svg>
  )
}

Gallery.defaultProps = {};

export default Gallery;
