import delve from "dlv";
import {getStrapiMedia} from "@utils/strapi";
import 'keen-slider/keen-slider.min.css'
import Image from 'next/image'
import {useKeenSlider} from "keen-slider/react";

const animation = { duration: 20000, easing: (t: any) => t }

const SponsorGallery = ({ Sponsors }: any) => {

  Sponsors = Sponsors.filter((sponsor: any) => {
    if (sponsor.isU19) {
      return false; // skip
    }
    return true;
  })
  const [sliderRef] = useKeenSlider({
    loop: true,
    renderMode: 'performance',
    created(s) {
      s.moveToIdx(5, true, animation)
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation)
    },
    breakpoints: {
      "(min-width: 768px)": {
        slides: { perView: 4, spacing: 30  },
      },
      "(min-width: 1200px)": {
        slides: { perView: 6, spacing: 20  },
      },
    },
    slides: { perView: 2 , spacing: 30 },
  })

  return (
    <>
      <div className="bg-brand flex flex-row w-full mt-8">
        <div ref={sliderRef} className="keen-slider">
        {
          Sponsors && Sponsors.map((sponsor: any, index: any) => {
            if (delve(sponsor, 'data.attributes.Url')) {
              return <div key={`sponsor-index-${index}`} className="keen-slider__slide">
                <a key={index} target="_blank" href={delve(sponsor, 'data.attributes.Url')}>
                <Image
                  className="object-contain"
                  src={getStrapiMedia(delve(sponsor.Logo, 'data.attributes.url')) || ""}
                  alt={delve(sponsor.Logo, 'data.attributes.alternativeText') || ""}
                  width={300}
                  height={300}
                  priority={false}
                  quality="85"
                />
              </a></div>
            } else {
              return <div key={`sponsor-index-${index}`} className="keen-slider__slide">
                <Image
                  className="object-contain"
                  src={getStrapiMedia(delve(sponsor.Logo, 'data.attributes.url')) || ""}
                  alt={delve(sponsor.Logo, 'data.attributes.alternativeText') || ""}
                  width={300}
                  height={300}
                  priority={false}
                  quality="85"
                />
              </div>
            }
          })
        }
        </div>
      </div>
    </>
  )
}

SponsorGallery.defaultProps = {};

export default SponsorGallery;
