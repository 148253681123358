import {getStrapiMedia} from "@utils/strapi";
import delve from "dlv";

const FeaturedVideo = ({ Title, CTA, Cover, Link}: any) => {
  return (
    <div className="mb-20" style={{ position: 'relative', minHeight: '250px', backgroundSize: 'cover', backgroundImage: 'url(' + getStrapiMedia(delve(Cover, 'data.attributes.url')) || undefined + ')'}}>
      <div className="bg-white p-2 absolute bottom-0 left-0 -mb-20" style={{maxWidth: '90%'}}>
        <h4 style={{maxWidth: '95%'}}>{Title}</h4>
        <div className="text-right text-sm mt-2">
          <a className="brand-link" href={Link}>{CTA}</a>
        </div>
      </div>
    </div>
  )
}

FeaturedVideo.defaultProps = {};

export default FeaturedVideo;
