import 'keen-slider/keen-slider.min.css'
import Image from 'next/image'
import Link from 'next/link'
import {useKeenSlider} from "keen-slider/react";
import {useState} from "react";

const ProductGallery = ({products}: any) => {

  const animation = { duration: 20000, easing: (t: any) => t }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    renderMode: 'performance',
    breakpoints: {
      "(min-width: 768px)": {
        slides: { perView: 2.5, spacing: 30 },
      },
      "(min-width: 1024px)": {
        slides: { perView:3.5, spacing: 20  },
      },
    },
    slides: { perView: 2.25 , spacing: 15 },
  })

  // if gallery is empty, do not show it
  if (!products || products?.length < 1) {
    return null;
  }

  return (

    <>
      <div className="my-4 relative">
        <div className="px-10">
          <div ref={sliderRef} className="keen-slider">
            {products.map((product: any, i: any) => {
              return (
                <div key={i} className="keen-slider__slide">
                  <a style={{cursor: 'pointer'}} aria-label={product.name}>
                    <Link legacyBehavior href={`/shop/${product.slug}`}>
                      <div className="relative overflow-hidden" key={i}>
                        <Image
                          className="object-cover transform-all duration-500"
                          src={product.images[0]?.url}
                          alt={product.name || 'Product Image'}
                          width={480}
                          height={480}
                          priority={i === 0}
                          quality="85"
                        />
                        <span className="block py-2 w-full"></span>
                        <div className="left-0 bg-white p-2 w-full text-center z-10">
                          <h4 className="line-clamp-2 line-height-1">{product.name}</h4>
                          <div className="text-sm text-accent-5">
                            {`${(Math.round(product.price.value * 100) / 100).toFixed(2)}`} {`${product.price.currencyCode}`}
                            {
                              product.variants[0].listPrice &&
                              typeof product.variants[0].listPrice === 'number' &&
                              product.variants[0].listPrice !== product.price.value ?
                                <span className="text-accent-3 mt-1 ml-2 bg-primary"
                                      style={{textDecoration: 'line-through'}}>
                                  {(Math.round(product.variants[0].listPrice * 100) / 100).toFixed(2)}
                                  {product.price.currencyCode}
                                </span> : null
                            }
                          </div>
                        </div>
                      </div>
                    </Link>
                  </a>
                </div>
              )
            })}
          </div>
        </div>
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e: { stopPropagation: () => any; }) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e: { stopPropagation: () => any; }) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1 /* todo: here it should access the perview breakpoint value instead of overall length or it will never reach last slide to be disabled */
              }
            />
          </>
        )}

      </div>
    </>
  )
}

// @ts-ignore
function Arrow(props) {
  const disabled = props.disabled ? " arrow--disabled" : ""
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!props.left && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  )
}


ProductGallery.defaultProps = {};

export default ProductGallery;
