import delve from "dlv";
import {Header, Layout} from '@components/common'
import Head from 'next/head'
import Hero from '../components/CmsBlocks/Hero';
import FeaturedPodcast from '../components/CmsBlocks/FeaturedPodcast';
import Article from '../components/CmsBlocks/Article';
import FeaturedArticle from '../components/CmsBlocks/FeaturedArticle';
import FeaturedVideo from '../components/CmsBlocks/FeaturedVideo';
import SponsorGallery from '../components/CmsBlocks/SponsorGallery';
import Recommendation from '../components/CmsBlocks/Recommendation';
import ProductGallery from '../components/CmsBlocks/ProductGallery';
import Gallery from "@components/CmsBlocks/Gallery";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import commerce from "@lib/api/commerce";
import {productConnectionFragment} from "@framework/utils/queries/get-all-products-query";
import {normalizeProduct} from "@framework/utils";
import History from "@components/CmsBlocks/History";

export function redirectToHomepage() {
  return {
    redirect: {
      destination: `/`,
      permanent: false,
    },
  };
}

export function redirectTo404Page() {
  return {
    redirect: {
      destination: `/404`,
      permanent: false,
    },
  };
}
export function redirectTo308(url: string) {
  return {
    redirect: {
      destination: url,
      permanent: true,
    },
  };
}
export function redirectToShopifyTrackingPage(path: string, query: string) {
  const url = `https://bora-hansgrohe-official.myshopify.com${path}${query}`;
  return {
    redirect: {
      destination: url,
      permanent: false,
    },
  };
}
export function redirectToShop() {
  return {
    redirect: {
      destination: `/shop`,
      permanent: false,
    },
  };
}

export const fetchJson: any = (url: string, subset: string | null = null, rename = null) => {
  return new Promise(async (resolve) => {
    if (!url) {
      resolve(null);
      return;
    }
    const response = await fetch(url);
    let json = await response.json();
    if (subset) {
      /* @ts-ignore */
      json = delve(json, subset);
    }
    resolve(json);
  });
}

/* just a helper function to create url params
for populating fields in API response*/
export const createPopulationQuery = (elements: any[]) => {
  if (!elements) {
    return "";
  }
  const query = [];
  for (let i = 0; i < elements.length; i++) {
    query.push("populate[" + i + "]=" + elements[i]);
  }
  return query.join('&');
}

export async function getShopNavigation(locale: string): Promise<any> {
  const url = `${process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"}`;
  const query = createPopulationQuery([
    '*',
    'ShopNavigationColumn',
    'ShopNavigationColumn.ShopNavigationItem',
    'blocks.link',
  ]);
  return fetchJson(url + '/api/shop-navigation?' + query + '&locale=' + locale, 'data.attributes');
}

const getProductsByCollectionQuery = /* GraphQL */ `
  query getAllProducts(
    $first: Int = 250,
    $collection: String!
  ) {
    collection(handle: $collection) {
      products(
        first: $first
      ) {
        ...productConnection
      }
    }
  }

  ${productConnectionFragment}
`;

async function getProductsByCollection(
  { query = getProductsByCollectionQuery , collection , config: cfg }: any
) {
  const { fetch , locale  } = commerce.getConfig(cfg);
  let { data } = await fetch(query, {
    variables: {collection: collection}
  }, {
    ...locale && {
      headers: {
        "Accept-Language": locale
      }
    }
  });
  if (
    !data ||
    !data.collection ||
    !data.collection.products ||
    !data.collection.products.edges
  ) {
    return {
      products: []
    }
  }
  return {
    ...data.collection.products.edges && {
      products: data.collection.products.edges.map(({node}: any) => {
        return normalizeProduct(node);
      })
    }
  };
}

const getProductData = (slug: any = null, collection = null, locale: any = null) => {
  return new Promise(async (resolve) => {
    const config = {
      locale: locale
    }
    const preview = false;
    if (!slug && !collection) {
      const {products} = await commerce.getAllProducts({
        variables: {first: 12},
        config,
      });
      resolve(products);
    } else if (slug) {
      const { product } = await commerce.getProduct({
        variables: { slug: slug},
        config,
        preview,
      })

      resolve(product);
    } else if (collection) {
      const {products} = await getProductsByCollection({
        collection:  collection,
        config,
      });
      resolve(products);
    } else {
      resolve(null);
    }

  });
};

export async function getServerSideProps(context: any) {

  try {
    const { locale } = context;
    const url = `${process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"}`;
    const articles: any = await fetchJson(url + '/api/articles?populate=*&sort=Date%3Adesc&pagination[page]=1&pagination[pageSize]=5&locale=' + locale, 'data');

    const populationQuery = createPopulationQuery([
      '*',
      'blocks.link',
      'blocks.image',
      'blocks.Images',
      'blocks.Media',
      'blocks.Text',
      'blocks.Media.Title',
      'blocks.Media.CoverImage',
      'blocks.Media.image_gallery',
      'blocks.Products',
      'blocks.Products.Collection',
      'blocks.Media.image_gallery.data',
      'blocks.Media.image_gallery.Title',
      'blocks.Media.image_gallery.Media',
      'blocks.Media.image_gallery.Gallery',
      'blocks.Media.image_gallery.Gallery',
      'blocks.image-gallery',
      'blocks.image-gallery.Gallery',
      'blocks.Gallery',
      'blocks.imageGallery',
      'blocks.imageGallery.Gallery',
      'blocks.image-gallery',
      'blocks.image-gallery.Gallery',
      'blocks.Columns',
      'blocks.Columns.Image',
      'blocks.Column',
      'blocks.Column.Image',
      'blocks.Cover',
      'SeoComponent',
      'SeoComponent.SeoMetaRepeatableComponent',
      'SeoComponent.SeoMetaRepeatableComponent.metaImage',
      'SeoComponent.*',
      'Recommendations',
      'Recommendations.Cover',
      'Recommendations.Cover',
    ]);
    const uri = '/api/homePage?' + populationQuery + "&locale=" + locale;

    const {
      Title,
      Slug,
      SeoComponent,
      blocks,
      Recommendations
    }: any = await fetchJson(url + uri, 'data.attributes');

    const sponsors = await fetchJson(url + '/api/sponsor-gallery?populate[0]=Sponsors.Url&populate[1]=Sponsors.Name&populate[2]=Sponsors.Logo', 'data.attributes');
    const preview = false;
    const config = { locale };
    if (blocks && blocks.length > 0) {
      let final_promises: any = [];
      blocks.forEach((data: any) => {
        let promises = [];
        switch (data.__component) {
          case 'blocks.image-gallery':
            const flickrApiKey = "3849884059e15ddd371a2300cb308d10";
            const method = "flickr.photosets.getInfo";
            const userId = "125658326@N04";
            const extras = "url_sq,url_t,url_s,url_q,url_m,url_n,url_z,url_c,url_l,url_o";
            promises.push(new Promise(async (resolve) => {
              data.albumData = [];
              Promise.all(data.Gallery.map(async (x: {flickrId: string}) => {
                // For each gallery get data such as name and cover image
                const url = "https://api.flickr.com/services/rest/?method=" + method + "&api_key=" + flickrApiKey + "&user_id=" + userId + "&photoset_id=" + x.flickrId + "&format=json&primary_photo_extras=" + extras + "&nojsoncallback=1"
                const res = await fetchJson(url);
                data.albumData.push(res);
                resolve(null);
              })).then(() => {
                resolve(null);
              });
            }));
            break;
          case 'blocks.product-gallery':
            promises.push(new Promise(async (resolve) => {
              data.productData = await getProductData(null, data?.Products?.Collection, locale);
              resolve(null);
            }));
            break;
        }
        if (promises) {
          final_promises = [...final_promises, ...promises];
        }
      })
      await Promise.all(final_promises);
    }

    return {
      props: {
        ...(await serverSideTranslations(locale, ["common", "home"])),
        ...(await getShopNavigation(locale)), // makes "ShopNavigationColumn" property available on page props
        Title,
        Slug,
        locale,
        SeoComponent,
        blocks,
        Recommendations,
        articles,
        sponsors
      }
    };
  } catch (error) {
    console.error(error)
    return  redirectTo404Page();
  }
}

export default function Home(
  {
    Title,
    Slug,
    locale,
    SeoComponent,
    products,
    blocks,
    Recommendations,
    articles,
    sponsors
  }: any) {
  return (
    <div>
      <Head>
        <title>{Title}</title>
        {SeoComponent ? (
          <meta
            name="description"
            content={SeoComponent.metaDescription}
            key="description"
          ></meta>
        ) : null}
        {SeoComponent?.SeoMetaRepeatableComponent
          ? SeoComponent.SeoMetaRepeatableComponent.map(
            ({ id, name, content, structuredData, metaImage }: any, index: any) => {
              return <meta key={name} name={name} content={delve(metaImage, 'data.attributes.url') || content}></meta>
            }
          )
          : null}
      </Head>
      <div className="container-rg">
        <Header></Header>
      </div>
      <div className="">
        <div className="container-lg">
          { articles && articles.slice(0,1).map((article: any, index: any) => {
            return <FeaturedArticle key={`article-index-${index}`} { ...article } />
          })}
        </div>
      </div>
      <div className="lg:flex flex-row container-rg">
        <div className="lg:w-1/4 md:grid md:grid-cols-3 md:gap-4 lg:block">
          { articles && articles.slice(1,4).map((article: any, index: any) => {
            return <Article key={`article-index-${index}`} { ...article } />
          })}
        </div>
        <div className="lg:w-3/4 lg:pl-5">
          { blocks && blocks.map((block: any, index: any) => {
            return renderBlock(block, index, block.productData, block.albumData)
          })}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 container-rg">
        { Recommendations && Recommendations.slice(0, 4).map((recommendation: any, index: any) => {
          return <div key={`recommendation-index-${index}`}><Recommendation { ...recommendation } /></div>
        })}
      </div>
      { sponsors && <SponsorGallery { ...sponsors } />}
    </div>
  )
}

const renderBlock = ({ __component, ...data }: any, index: any, products = null, albums = null) => {
  let Block;
  switch (__component) {
    case 'blocks.history':
      Block = History;
      break;
    case 'blocks.hero':
      Block = Hero;
      break;
    case 'blocks.featured-podcast':
      Block = FeaturedPodcast;
      break;
    case 'blocks.featured-video':
      Block = FeaturedVideo;
      break;
    case 'blocks.image-gallery':
      return <Gallery key={`block-index-${index}`} albums={albums}></Gallery>;
      break;
    case 'blocks.product-gallery':
      return <ProductGallery key={`block-index-${index}`} products={products} />
      break;
  }

  return Block ? <Block key={`block-index-${index}`} {...data} /> : null;

};

Home.Layout = Layout
