import {getStrapiMedia} from "@utils/strapi";
import delve from "dlv";
import {Button} from "@components/ui";

const Hero = ({image, header, link}: any) => {
  return (
    <div className="text-center mb-5">
      <div className="w-full bg-cover aspect-video" style={{ backgroundImage: 'url(' + getStrapiMedia(delve(image, 'data.attributes.url')) || undefined + ')'}}></div>
      <div className="-mt-10 -mb-4 w-11/12 bg-brand mx-auto pt-5 px-8 pb-12 text-center">
        <h3 className="text-white">{header}</h3>
      </div>
      <Button className="-mt-8 mx-auto" Component={'a'} variant={'dark'} href={link.href}>{link.label}</Button>
    </div>
  )
}

Hero.defaultProps = {};

export default Hero;
