import { getStrapiMedia } from "@utils/strapi";
import delve from 'dlv';
import Link from 'next/link'
import {useTranslation} from "next-i18next";
import {useRouter} from "next/router";

const Article = ({ attributes }: any) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  return (
    <>
    <Link legacyBehavior href={`/news/${attributes.Slug}`} aria-label={attributes.title}>
       <div className="mb-5">
         {
           attributes.image?.data?.attributes && <div className="w-full bg-cover aspect-video" style={{ backgroundImage: 'url(' + getStrapiMedia(delve(attributes.image, 'data.attributes.url')) || undefined + ')'}}></div>
         }
         {
           attributes.legacyImagePimcore && <div className="w-full bg-cover aspect-video" style={{ backgroundImage: 'url(' + attributes.legacyImagePimcore + ')'}}></div>
         }
         <div className="-mt-5 px-4 py-3 bg-white md:mr-4">
           {/* todo: show category and timestamp*/}
           <small className="text-brand">
             {
               new Date(attributes.Date).toLocaleDateString('de-de',
                     {year: "numeric", month: "long", day: "numeric"})
             }
           </small>
           <h4>{attributes.title}</h4>
           <div className="text-right text-sm mt-2">
             <Link legacyBehavior href={`/news/${attributes.Slug}`}>
               <a className="brand-link">{t("readmore")}</a>
             </Link>
           </div>
         </div>
       </div>
    </Link>
    </>
  )
}

Article.defaultProps = {};

export default Article;
