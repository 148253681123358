import Image from "next/image";
import {getStrapiMedia} from "@utils/strapi";
import delve from "dlv";
import { useTranslation } from "next-i18next";
import {Text} from "@components/ui";

const FeaturedPodcast = ({ Cover, Title, CTA, Link, Description }: any) => {
  const { t } = useTranslation("common");

  return (
    <div className="relative my-4">
      <div className="absolute -z-0 top-0 bg-brand h-full md:h-5/6 w-full"></div>
      <div className="relative px-8 pb-6 md:pb-0">
        <h4 className="py-4 text-dark uppercase">{t("our_podcast")}</h4>
        <div className="flex gap-8">
          <div className="lg:w-1/3 ">
            <Image
              className="rounded-2xl"
              src={getStrapiMedia(delve(Cover, 'data.attributes.url')) || ""}
              alt={delve(Cover, 'data.attributes.alternativeText') || ""}
              width={480}
              height={480}
              priority={false}
              quality="85"
            />
          </div>
          <div className="lg:w-2/3 text-white">
            <h4>{Title}</h4>
            <Text className="mb-4">{Description}</Text>
            <div className="text-right">
              <a className="brand-link text-white" href={Link}>
                {CTA}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

FeaturedPodcast.defaultProps = {};

export default FeaturedPodcast;
