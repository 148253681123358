import {getStrapiMedia} from "@utils/strapi";
import delve from 'dlv';
import Link from "next/link";
import {attributes} from "js-cookie";
import {useTranslation} from "next-i18next";

const FeaturedArticle = ({attributes}: any) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Link legacyBehavior href={`./news/${attributes.Slug}`} aria-label={attributes.title}>
        <div className="mb-5 relative bg-no-repeat bg-contain md:bg-cover aspect-video md:mb-20 lg:mb-16 pt-40"
             style={{backgroundImage: 'url(' + getStrapiMedia(delve(attributes.image, 'data.attributes.url')) || undefined + ')'}}>
          <div className="md:absolute bg-white left-0 bottom-0 p-4 md:-mb-20 lg:-mb-10 md:max-w-[90%] lg:max-w-[55%]">
            <small className="text-dark">
              {
                new Date(attributes.Date).toLocaleDateString('de-de',
                  {year: "numeric", month: "long", day: "numeric"})
              }
            </small>
            {/* todo show category and timestamp*/}
            <h2>{attributes.title}</h2>
            <div className="text-right mt-3">
              <a className="brand-link" href={attributes.Slug}>{t("readmore")}</a>
            </div>
          </div>
        </div>
      </Link>
    </>
  )
}

FeaturedArticle.defaultProps = {};

export default FeaturedArticle;
